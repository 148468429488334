import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { Box, Grid } from '@mui/material';
import { TextEditor } from '@/components';
import { DEFAULT_FORMAT_OPTIONS_WITH_MENTION } from '@/utils/constants';
import commonStyles from '@/utils/styles';
import { MentionItemType, UserType } from '@/utils/types';

const styles = {
	containerStyles: {
		...commonStyles.article,
		'.ql-editor': {
			minHeight: 'calc(100vh - 300px)',
			padding: '45px 102px',
			borderRadius: '0 0 10px 10px',
		},
		'.quill:not(:has(.ql-toolbar)) .ql-editor': {
			borderRadius: '10px',
		},
		'.quill:not(:has(.ql-toolbar)) .ql-container.ql-snow': {
			borderTop: 'none !important',
		},
		'.ql-container.ql-snow': {
			boxShadow: '0px 7px 20px rgba(40, 41, 61, 0.08)',
			border: 'none',
		},
		'.ql-toolbar.ql-snow': {
			position: 'sticky',
			boxShadow: '0px 6px 14px rgba(40, 41, 61, 0.08)',
			border: 'none',
			top: '0',
			zIndex: 1000,
			backgroundColor: '#f9f9fb',
		},
		'.ql-toolbar::before': {
			content: '""',
			height: '25px',
			backgroundColor: '#f9f9fb',
			position: 'absolute',
			bottom: '40px',
			left: '-20px',
			right: '-10px',
		},
		// Heading 1 inside toolbar menu
		".ql-snow .ql-header .ql-picker-item[data-value='1']::before": {
			color: '#a94943',
		},
		'.ql-snow h1': {
			color: '#a94943 !important',
		},
		'.ql-snow h1 span': {
			color: '#a94943 !important',
		},
		'.ql-snow h1 strong': {
			color: '#a94943 !important',
		},
		'.ql-snow h1::after': {
			content: '""',
			display: 'block',
			position: 'relative',
			width: '940px',
			left: '-62px',
			height: '1px',
			backgroundColor: '#cddee7',
			margin: '36px 0 68px',
		},
		'.ql-mention-list-container li': {
			lineHeight: '44px',
		},
		ol: {
			padding: 0,
		},
		ul: {
			padding: 0,
		},
	},
	emptyEditorContainer: {
		height: 'calc(100vh - 300px)',
		backgroundColor: '#fff',
		borderRadius: '10px',
		boxShadow: '0px 7px 20px rgba(40, 41, 61, 0.08)',
	},
};

type EditorProps = {
	codox?: Record<string, any>;
	docId?: string;
	getDoc?: () => void;
	handleUpdateText?: (_key: string, _value: string) => void;
	readOnly?: boolean;
	text?: string;
	title?: string;
	type?: 'summary' | 'notes' | 'entityNotes';
	user?: UserType | null;
	usersForMentions?: MentionItemType[];
};

export const Editor = ({
	codox,
	docId,
	getDoc,
	handleUpdateText,
	readOnly = false,
	text,
	type = 'summary',
	user,
	usersForMentions,
}: EditorProps) => {
	const [updatingMentionableUsers, setUpdatingMentionableUsers] = useState(false);

	const saveHandler = useRef(
		debounce((text) => {
			handleUpdateText && handleUpdateText(type, text);
		}, 1000),
	);

	useEffect(() => {
		if (!usersForMentions) return;
		setUpdatingMentionableUsers(true);

		const timeout = setTimeout(() => {
			setUpdatingMentionableUsers(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [usersForMentions]);

	const onChange = (editorText: string) => {
		saveHandler.current(editorText);
	};

	return (
		<Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				direction={'column'}
				height={'100%'}
				wrap="nowrap"
				sx={{ maxWidth: 1050, width: '100%' }}
				px={2}
			>
				<Grid item flexGrow={1}>
					{updatingMentionableUsers ? (
						<Box sx={styles.emptyEditorContainer} />
					) : (
						<TextEditor
							atValues={usersForMentions}
							dynamicToolbar={true}
							codox={codox}
							docId={`${docId}-${type}`}
							getDoc={getDoc}
							containerStyles={styles.containerStyles}
							formatOptions={DEFAULT_FORMAT_OPTIONS_WITH_MENTION}
							onChange={onChange}
							value={text}
							user={user}
							readOnly={readOnly}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
